import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpClient from "../../utils/httpClient";

export const fetchRegions = createAsyncThunk(
  "region/fetchRegions",
  async (_, { rejectWithValue }) => {
    try {
      const getRegions = await httpClient.get("/regionalRelationship/regions");
      return getRegions?.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postNewRegion = createAsyncThunk(
  "region/postNewRegion",
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        region: { newRegion },
      } = getState();
      const data = newRegion;
      const requestNewRegion = await httpClient.post(
        "/regionalRelationship/regions",
        data
      );
      return requestNewRegion?.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRegion = createAsyncThunk(
  "region/deleteRegion",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { region } = getState();
      const requestDeleteRegion = await httpClient.delete(
        `/regionalRelationship/regions/${region.selectedRegion.id}`
      );
      return requestDeleteRegion.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postEditRegion = createAsyncThunk(
  "region/postEditRegion",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { region } = getState();
      const data = region.newRegion;
      const requestEditRegion = await httpClient.patch(
        `/regionalRelationship/regions/${region.selectedRegion.id}`,
        data
      );
      return requestEditRegion?.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSattiondata = createAsyncThunk(
  "region/getSattiondata",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { region } = getState();
      const data = region.selectedRegion;
      const requestforSattiondata = await httpClient.post(
        `/regionalRelationship/get-stations-of-region`,
        { regionName: data.regionName }
      );
      return requestforSattiondata?.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const RegionalRelationship = createSlice({
  name: "region",
  initialState: {
    showAddRegionModal: false,
    showDeleteRegionModal: false,
    showEditRegionModal: false,
    newRegion: {},
    selectedRegion: {},
    reloadTable: false,
    reloadapi: false,
    api: {
      regions: {
        data: [],
        loading: "idle",
        error: null,
      },

      addRegion: {
        data: [],
        loading: "idle",
        error: null,
      },
      editRegion: {
        data: [],
        loading: "idle",
        error: null,
      },
      deletedRegion: {
        data: [],
        loading: "idle",
        error: null,
      },
      stationsOfRegion: {
        data: [],
        loading: "idle",
        error: null,
      },
    },
  },
  reducers: {
    toggleAddRegionModal: (state) => {
      state.showAddRegionModal = !state.showAddRegionModal;
    },
    toggleDeleteRegionModal: (state) => {
      state.showDeleteRegionModal = !state.showDeleteRegionModal;
    },
    toggleEditRegionModal: (state) => {
      state.showEditRegionModal = !state.showEditRegionModal;
    },
    setNewRegion: (state, action) => {
      state.newRegion = action.payload;
    },
    setSelectedRegion: (state, action) => {
      state.selectedRegion = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchRegions.pending, (state, action) => {
        if (state.api.regions.loading === "idle") {
          state.api.regions.loading = "pending";
        }
      })
      .addCase(fetchRegions.fulfilled, (state, action) => {
        if (state.api.regions.loading === "pending") {
          state.api.regions.loading = "idle";
          state.api.regions.data = action.payload;
          state.reloadTable = true;
        }
      })
      .addCase(fetchRegions.rejected, (state, action) => {
        if (state.api.regions.loading === "pending") {
          state.api.regions.loading = "idle";
          state.api.regions.error = action.error;
          state.reloadTable = true;
        }
      })
      .addCase(postNewRegion.pending, (state, action) => {
        if (state.api.addRegion.loading === "idle") {
          state.api.addRegion.loading = "pending";
          state.reloadTable = false;
        }
      })
      .addCase(postNewRegion.fulfilled, (state, action) => {
        if (state.api.addRegion.loading === "pending") {
          state.api.addRegion.loading = "idle";
          state.api.addRegion.data = action.payload;
          state.reloadapi = true;
        }
      })
      .addCase(postNewRegion.rejected, (state, action) => {
        if (state.api.addRegion.loading === "pending") {
          state.api.addRegion.loading = "idle";
          state.api.addRegion.error = action.error;
          // state.reloadapi = true;
        }
      })

      .addCase(postEditRegion.pending, (state, action) => {
        if (state.api.editRegion.loading === "idle") {
          state.api.editRegion.loading = "pending";
          state.reloadapi = false;
        }
      })
      .addCase(postEditRegion.fulfilled, (state, action) => {
        if (state.api.editRegion.loading === "pending") {
          state.api.editRegion.loading = "idle";
          state.api.editRegion.data = action.payload;
          state.reloadapi = true;
        }
      })
      .addCase(postEditRegion.rejected, (state, action) => {
        if (state.api.editRegion.loading === "pending") {
          state.api.editRegion.loading = "idle";
          state.api.editRegion.error = action.error;
        }
      })

      .addCase(deleteRegion.pending, (state, action) => {
        if (state.api.deletedRegion.loading === "idle") {
          state.api.deletedRegion.loading = "pending";
          state.reloadapi = false;
        }
      })
      .addCase(deleteRegion.fulfilled, (state, action) => {
        if (state.api.deletedRegion.loading === "pending") {
          state.api.deletedRegion.loading = "idle";
          state.api.deletedRegion.data = action.payload;
          state.reloadapi = true;
        }
      })
      .addCase(deleteRegion.rejected, (state, action) => {
        if (state.api.deletedRegion.loading === "pending") {
          state.api.deletedRegion.loading = "idle";
          state.api.deletedRegion.error = action.error;
        }
      })
      .addCase(getSattiondata.pending, (state, action) => {
        if (state.api.stationsOfRegion.loading === "idle") {
          state.api.stationsOfRegion.loading = "pending";
        }
      })
      .addCase(getSattiondata.fulfilled, (state, action) => {
        if (state.api.stationsOfRegion.loading === "pending") {
          state.api.stationsOfRegion.loading = "idle";
          state.api.stationsOfRegion.data = action.payload;
        }
      })
      .addCase(getSattiondata.rejected, (state, action) => {
        if (state.api.stationsOfRegion.loading === "pending") {
          state.api.stationsOfRegion.loading = "idle";
          state.api.stationsOfRegion.error = action.error;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleAddRegionModal,
  toggleDeleteRegionModal,
  toggleEditRegionModal,
  setNewRegion,
  setSelectedRegion,
  setEditedRegion,
} = RegionalRelationship.actions;

export default RegionalRelationship.reducer;
