import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpClient from "../../utils/httpClient";

export const fetchAllActionStatus = createAsyncThunk(
  "all-action-status/position-spare-txn",
  async (_, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(
        "/all-action-status/position-spare-txn"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const AllActionStatusSliceReducer = createSlice({
  name: "allactionstatus",
  initialState: {
    allActionStatusLoading: "idle",
    stationLoading: "idle",
    error: null,
    responseAllActionStatus: [],
    allActionStatusPlayload: {},
    downloadZipState: {
      isError: false,
      message: "",
    },
  },
  reducers: {
    setAllActionStatusPlayload: (state, action) => {
      state.allActionStatusPlayload = action.payload;
    },
    setDownloadZipState: (state, action) => {
      state.downloadZipState = {
        ...state.downloadZipState,
        ...action.payload,
      };
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(fetchAllActionStatus.pending, (state, action) => {
        if (state.allActionStatusLoading === "idle") {
          state.allActionStatusLoading = "pending";
        }
      })
      .addCase(fetchAllActionStatus.fulfilled, (state, action) => {
        if (state.allActionStatusLoading === "pending") {
          state.allActionStatusLoading = "idle";
          state.responseAllActionStatus = action.payload;
        }
      })
      .addCase(fetchAllActionStatus.rejected, (state, action) => {
        if (state.allActionStatusLoading === "pending") {
          state.allActionStatusLoading = "idle";
          state.error = action.error;
        }
      });
  },
});

export const { setAllActionStatusPlayload, setDownloadZipState } =
  AllActionStatusSliceReducer.actions;

export const sDownloadZipState = (store) =>
  store.allActionStatus.downloadZipState;

export default AllActionStatusSliceReducer.reducer;
