import { createSlice } from "@reduxjs/toolkit";

export const UsersSlice = createSlice({
  name: "users",
  initialState: {
    data: [],
    userId: "",
    win2kId: null,
    access:[],
  },
  reducers: {
    setData: (state, action) => {
      var dataNew = JSON.parse(sessionStorage.getItem("data"));
      state.data = dataNew;
      state.win2kId = state.data.win2kId;
    },
    setAccess: (state, action) => {
      let functionalityAccess = []
      action.payload.data.map((data) => {
        functionalityAccess.push(data.functionality)
      })
      state.access = functionalityAccess
    }
  },
});

export const { setData, setAccess } = UsersSlice.actions;

export default UsersSlice.reducer;
