import { createSlice } from "@reduxjs/toolkit";

export const consumeSpareReducer = createSlice({
  name: "consume-spare",
  initialState: {
    selectedSpares: [],
    submittedConsumeSpareState: {
      isOpenStatusDialog: false,
      type: "success",
      message: "",
    },
  },
  reducers: {
    setSelectedSpares: (state, action) => {
      state.selectedSpares = action.payload;
    },
    setSubmittedConsumeSpareState: (state, action) => {
      state.submittedConsumeSpareState = action.payload;
    },
  },
});

export const { setSelectedSpares, setSubmittedConsumeSpareState } =
  consumeSpareReducer.actions;

export const sSelectedSpares = (rootStore) =>
  rootStore.consumeSpare.selectedSpares;
export const sSubmittedConsumeSpareState = (rootStore) =>
  rootStore.consumeSpare.submittedConsumeSpareState;

export default consumeSpareReducer.reducer;
