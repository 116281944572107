import { createSelector, createSlice } from "@reduxjs/toolkit";
import { extendedApiSlice } from "./apis";

export const initialState = {};

const messageBroadcastSlice = createSlice({
  name: "messageBroadcast",
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {},
});

const selfSelect = (state) => state.messageBroadcast;

export const select = createSelector(selfSelect, (state) => state);

export const { reset } = messageBroadcastSlice.actions;

export default messageBroadcastSlice.reducer;
