import { createSelector, createSlice } from "@reduxjs/toolkit";
import { extendedApiSlice } from "./apis";
import { AppRouteEnums } from "../../constants/route";

export const initialState = {
  overview: {
    selectedItems: [],
  },
  details: {},
  submitedData: {},
};

const consumeSpareSlice = createSlice({
  name: "mmdConsumeSpare",
  initialState,
  reducers: {
    setSelectedItems: (state, action) => {
      state.overview.selectedItems = action.payload;
    },
    clearSubmitedData: (state) => {
      state.submitedData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      extendedApiSlice.endpoints.submitConsumeSpare.matchFulfilled,
      (state, { payload }) => {
        state.submitedData = payload;
      }
    );
  },
});

const selfSelect = (state) => state.mmdConsumeSpare;

export const selectCurrentStation = createSelector(selfSelect, (state) => {
  if (state.overview.selectedItems[0]) {
    return state.overview.selectedItems[0]?.station;
  }
  return null;
});

export const selectSelectedItemIds = createSelector(selfSelect, (state) => {
  return state.overview.selectedItems.map((item) => item.id);
});

export const selectSubmitedRedirectTo = createSelector(selfSelect, (state) => {
  const { type } = state.submitedData;
  if (type === "SuccessOnly") {
    return AppRouteEnums.MMD_CONSUME_SPARE;
  }
  if (type === "SuccessPosition") {
    return AppRouteEnums.SPARE;
  }
  return undefined;
});

export const selectSubmitedData = createSelector(
  selfSelect,
  (state) => state.submitedData?.itemsPosition
);

export const { setSelectedItems, clearSubmitedData } =
  consumeSpareSlice.actions;

export default consumeSpareSlice.reducer;
