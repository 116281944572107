import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpClient from "../../utils/httpClient";

export const fetchStationData = createAsyncThunk(
  "spare/fetchStationData",
  async (_, { rejectWithValue }) => {
    try {
      const stationDataResult = await httpClient.get("/spare/station-list");
      return stationDataResult.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchStationCode = createAsyncThunk(
  "spare/fetchStationCode",
  async (stationCode, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(
        `/spare/station-list/${stationCode}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const requestParts = createAsyncThunk(
  "spare/requestParts",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(
        "/spare/position-spare/request-parts",
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const confrimPartRequest = createAsyncThunk(
  "spare/confrimPartRequest",
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        spare: { partsConfrimPlayload },
      } = getState();
      const payloadData = partsConfrimPlayload;

      const requestforParts = await httpClient.post(
        "/spare/position-spare/position-parts",
        payloadData
      );
      return requestforParts.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const SpareSlice = createSlice({
  name: "spare",
  initialState: {
    stationData: [],
    stationCode: [],
    responsePostionParts: [],
    defaultPositionPartsData: [],
    partsRequestPlayload: {},
    partsConfrimPlayload: {},
    loading: "idle",
    stationLoading: "idle",
    stationCodeLoading: "idle",
    partRequestloading: "idle",
    error: null,
    currentRequestId: undefined,
    confimationScreenPage: 0,
    showPartsTable: false,
    partsConfrimResponse: false,
    showConfimationScreen: false,
    showConfirmPartsResult: false,
  },
  reducers: {
    setShowConfimationScreen: (state, action) => {
      state.showConfimationScreen = !state.showConfimationScreen;
    },

    setShowConfirmParts: (state, action) => {
      state.showConfirmPartsResult = !state.showConfirmPartsResult;
    },
    setPartsRequestPlayload: (state, action) => {
      state.partsRequestPlayload = action.payload;
    },
    setPartsConfrimPlayload: (state, action) => {
      state.partsConfrimPlayload = action.payload;
    },
    updateQuantityToPosition: (state, action) => {
      const { index, value, rowIndex } = action.payload;
      state.responsePostionParts[index].airlineHouseStock.data[
        rowIndex
      ].quantityRequired = parseInt(value) || 0;
    },

    resetPostionParts: (state, action) => {
      state.responsePostionParts = state.defaultPositionPartsData;
    },
    resetState: (state) => {
      state.showPartsTable = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchStationData.pending, (state, action) => {
        if (state.stationLoading === "idle") {
          state.stationLoading = "pending";
        }
      })
      .addCase(fetchStationData.fulfilled, (state, action) => {
        if (state.stationLoading === "pending") {
          state.stationLoading = "idle";
          state.stationData = action.payload;
        }
      })
      .addCase(fetchStationData.rejected, (state, action) => {
        if (state.stationLoading === "pending") {
          state.stationLoading = "idle";
          state.error = action.error;
        }
      })
      .addCase(requestParts.pending, (state, action) => {
        if (state.partRequestloading === "idle") {
          state.partRequestloading = "pending";
        }
      })
      .addCase(requestParts.fulfilled, (state, action) => {
        if (state.partRequestloading === "pending") {
          state.partRequestloading = "idle";
          state.defaultPositionPartsData = action.payload;
          state.responsePostionParts = action.payload;
          state.showPartsTable = true;
        }
      })
      .addCase(requestParts.rejected, (state, action) => {
        if (state.partRequestloading === "pending") {
          state.partRequestloading = "idle";
          state.error = action.error;
          state.showPartsTable = false;
        }
      })
      .addCase(confrimPartRequest.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(confrimPartRequest.fulfilled, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.partsConfrimResponse = true;
          state.showConfirmPartsResult = true;
          state.showConfimationScreen = false;
        }
      })
      .addCase(confrimPartRequest.rejected, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.partsConfrimResponse = false;
          state.error = action.error;
          state.showConfirmPartsResult = true;
          state.showConfimationScreen = false;
        }
      })
      .addCase(fetchStationCode.pending, (state, action) => {
        if (state.stationCodeLoading === "idle") {
          state.stationCodeLoading = "pending";
        }
      })
      .addCase(fetchStationCode.fulfilled, (state, action) => {
        if (state.stationCodeLoading === "pending") {
          state.stationCodeLoading = "idle";
          state.stationCode = action.payload;
        }
      })
      .addCase(fetchStationCode.rejected, (state, action) => {
        if (state.stationCodeLoading === "pending") {
          state.stationCodeLoading = "idle";
          state.error = action.error;
        }
      });
  },
});

export const {
  setStationData,
  setShowConfimationScreen,
  setShowConfirmParts,
  setPartsRequestPlayload,
  setPartsConfrimPlayload,
  updateQuantityToPosition,
  resetPostionParts,
  resetState,
} = SpareSlice.actions;

export default SpareSlice.reducer;
