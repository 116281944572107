import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import { AppRouteEnums } from "./constants/route";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        index
        lazy={async () => {
          const { default: Component } = await import(
            "./features/login/LoginMain"
          );
          return { Component };
        }}
      />
      <Route
        path="olsp"
        lazy={async () => {
          const { default: Component } = await import(
            "./features/login/LoginMain"
          );
          return { Component };
        }}
      />
      <Route
        path="olsp/help"
        lazy={async () => {
          const { default: Component } = await import(
            "./features/login/LoginHelp"
          );
          return { Component };
        }}
      />
      <Route
        path="help"
        lazy={async () => {
          const { default: Component } = await import(
            "./features/login/LoginHelp"
          );
          return { Component };
        }}
      />
      <Route
        path="home"
        lazy={async () => {
          const { default: Component } = await import(
            "./features/login/LoginLanding"
          );
          return { Component };
        }}
      />
      <Route
        path="user"
        lazy={async () => {
          const { default: Component } = await import("./features/user/User");
          return { Component };
        }}
      />

      <Route
        path="allAccessManagement"
        lazy={async () => {
          const { default: Component } = await import(
            "./features/allAccessManagement/AllAccessManagement"
          );
          return { Component };
        }}
      />
      <Route
        lazy={async () => {
          const { default: Component } = await import(
            "./features/shared/Layout"
          );
          return { Component };
        }}
      >
        <Route
          path="spare"
          lazy={async () => {
            const { default: Component } = await import(
              "./features/spare/SparePositionLanding"
            );
            return { Component };
          }}
        />
        <Route
          path="viewstocks"
          lazy={async () => {
            const { default: Component } = await import(
              "./features/viewstocks"
            );
            return { Component };
          }}
        />
        <Route
          path="confirm-receipt"
          lazy={async () => {
            const { ConfirmReceiptOverview: Component } = await import(
              "./features/confirmReceipt"
            );
            return { Component };
          }}
        />
        <Route
          path="confirm-receipt-details"
          lazy={async () => {
            const { ConfirmReceiptDetails: Component } = await import(
              "./features/confirmReceipt"
            );
            return { Component };
          }}
        />
        <Route
          path="all-action-status"
          lazy={async () => {
            const { default: Component } = await import(
              "./features/allActionsStatus/AllActionStatus"
            );
            return { Component };
          }}
        />
        <Route
          path={AppRouteEnums.CONSUME_SPARE}
          lazy={async () => {
            const { default: Component } = await import(
              "./features/consumeSpare"
            );
            return { Component };
          }}
        />
        <Route
          path={AppRouteEnums.CONSUME_SPARE_DETAILS}
          lazy={async () => {
            const { default: Component } = await import(
              "./features/consumeSpareDetails"
            );
            return { Component };
          }}
        />
        <Route
          path={AppRouteEnums.CONSUME_SPARE_DETAIL}
          lazy={async () => {
            const { default: Component } = await import(
              "./features/consumeSpareDetail"
            );
            return { Component };
          }}
        />
        <Route
          path="mmd-overview"
          lazy={async () => {
            const { MMDConfirmReceiptOverview: Component } = await import(
              "./features/confirmReceipt"
            );
            return { Component };
          }}
        />
        <Route
          path="mmd-details"
          lazy={async () => {
            const { MMDConfirmReceiptDetails: Component } = await import(
              "./features/confirmReceipt"
            );
            return { Component };
          }}
        />
        <Route
          path={AppRouteEnums.MMD_CONSUME_SPARE}
          lazy={async () => {
            const { default: Component } = await import(
              "./features/mmdConsumeSpare/overview"
            );
            return { Component };
          }}
        />
        <Route
          path={AppRouteEnums.MMD_CONSUME_SPARE_DETAILS}
          lazy={async () => {
            const { default: Component } = await import(
              "./features/mmdConsumeSpare/details"
            );
            return { Component };
          }}
        />
        <Route
          path={AppRouteEnums.MMD_RECALL_SPARE}
          lazy={async () => {
            const { default: Component } = await import(
              "./features/mmdRecallSpare"
            );
            return { Component };
          }}
        />
        <Route
          path={AppRouteEnums.CONFIRM_RECEIPT_SIN_DETAILS}
          lazy={async () => {
            const { default: Component } = await import(
              "./features/confirmReceiptSin/details/ConfirmReceiptSinDetails"
            );
            return { Component };
          }}
        />
        <Route
          path={AppRouteEnums.CONFIRM_RECEIPT_SIN}
          lazy={async () => {
            const { default: Component } = await import(
              "./features/confirmReceiptSin/overview/ConfirmReceiptSinOverview"
            );
            return { Component };
          }}
        />
        <Route
          path="dispatch-spare-details"
          lazy={async () => {
            const { DispatchSparetDetails: Component } = await import(
              "./features/dispatchSpare"
            );
            return { Component };
          }}
        />
        <Route
          path={AppRouteEnums.DISPATCH_SPARE}
          lazy={async () => {
            const { DispatchSpareOverview: Component } = await import(
              "./features/dispatchSpare"
            );
            return { Component };
          }}
        />
        <Route
          path={AppRouteEnums.MESSAGE_BROADCAST}
          lazy={async () => {
            const { default: Component } = await import(
              "./features/messageBroadcast"
            );
            return { Component };
          }}
        />
        <Route
          path={AppRouteEnums.MESSAGE_BROADCAST_DETAIL}
          lazy={async () => {
            const { default: Component } = await import(
              "./features/messageBroadcastDetail"
            );
            return { Component };
          }}
        />
      </Route>
      <Route
        path="regionalrelationship"
        lazy={async () => {
          const { default: Component } = await import(
            "./features/RegionalRelationship/RelationshipLandingPage"
          );
          return { Component };
        }}
      />
      <Route
        path="stationList"
        lazy={async () => {
          const { default: Component } = await import(
            "./features/stationList/StationList"
          );
          return { Component };
        }}
      />
      <Route
        path="401"
        lazy={async () => {
          const { default: Component } = await import(
            "./features/error401/Error401"
          );
          return { Component };
        }}
      />
      <Route
        path="403"
        lazy={async () => {
          const { default: Component } = await import(
            "./features/error403/Error403"
          );
          return { Component };
        }}
      />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
