export const converter = {
  read: (value) => {
    if (value[0] === '"') {
      value = value.slice(1, -1);
    }
    return value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
  },
  write: (value) => {
    return encodeURIComponent(value).replace(
      /%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g,
      decodeURIComponent
    );
  },
};

const assign = (target) => {
  for (let i = 1; i < target.length; i++) {
    const source = target[i];
    for (const key in source) {
      target[key] = source[key];
    }
  }
  return target;
};

const defaultAttributes = {
  path: "/",
};

export const setCookie = (name, value, attributes) => {
  if (typeof document === "undefined" || !name) {
    return;
  }

  attributes = assign({}, defaultAttributes, attributes);

  if (typeof attributes.expires === "number") {
    attributes.expires = new Date(Date.now() + attributes.expires * 864e5);
  }
  if (attributes.expires) {
    attributes.expires = attributes.expires.toUTCString();
  }

  name = encodeURIComponent(name)
    .replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent)
    .replace(/[()]/g, escape);

  var stringifiedAttributes = "";
  for (var attributeName in attributes) {
    if (!attributes[attributeName]) {
      continue;
    }

    stringifiedAttributes += "; " + attributeName;

    if (attributes[attributeName] === true) {
      continue;
    }

    stringifiedAttributes += "=" + attributes[attributeName].split(";")[0];
  }

  return (document.cookie =
    name + "=" + converter.write(value, name) + stringifiedAttributes);
};

export const getCookie = (name) => {
  if (typeof document === "undefined" || !name) {
    return;
  }

  const cookies = document.cookie ? document.cookie.split("; ") : [];

  const jar = {};
  for (let i = 0; i < cookies.length; i++) {
    const parts = cookies[i].split("=");
    const value = parts.slice(1).join("=");

    try {
      const found = decodeURIComponent(parts[0]);
      if (!(found in jar)) jar[found] = converter.read(value, found);
      if (name === found) {
        break;
      }
    } catch (error) {
      //
    }
  }

  return name ? jar[name] : jar;
};

export const eraseCookie = (name) => {
  if (typeof document === "undefined" || !name) {
    return false;
  }

  document.cookie = name + "=; Max-Age=0";
  return true;
};
