import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import {
  errors,
  // , fakeRegionApi,
  // fakeTableApi
} from "./constants";
import httpClient from "../../utils/httpClient";

export const initialState = {
  search: {
    feetGroup: [],
    region: null,
    station: null,
    partNo: [],
    wildcards: [],
    isIncludeSIN: false,
    description: "",
    error: { type: "noError", message: "" },
  },
  api: {
    regionStation: {
      data: [],
      loading: "idle",
      currentRequestId: undefined,
      error: null,
    },
    resultView: {
      data: [],
      fullViewData: [],
      loading: "idle",
      currentRequestId: undefined,
      error: null,
    },
  },
};

export const fetchRegionStation = createAsyncThunk(
  "viewstocks/fetchRegionStation",
  async (_, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(
        "/viewStock/getAllRegionsAndStations"
      );
      return response?.data?.data;

      // const response = await fakeRegionApi();
      // return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { viewStocks } = getState();
      const { loading } = viewStocks.api.regionStation;
      if (loading === "pending") {
        return false;
      }
    },
  }
);

export const fetchResultView = createAsyncThunk(
  "viewstocks/fetchResultView",
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        viewStocks: { search },
      } = getState();
      const payload = {
        partNo: [...search.partNo, ...search.wildcards],
        fleetGroup: search.feetGroup.map(({ value }) => value),
        description: search.description,
        ...(search.station && { station: search.station.stationCode }),
        sinIncluded: search.isIncludeSIN,
        ...(search.region.value !== "All" && { region: search.region.value }),
      };

      const response = await httpClient.post("/viewStock/view", payload);

      return response?.data?.data;

      // const response = await fakeTableApi();
      // return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const viewStocksSlice = createSlice({
  name: "viewStocks",
  initialState,
  reducers: {
    setFeetGroup: (state, action) => {
      state.search.feetGroup = action.payload;
    },
    setRegion: (state, action) => {
      state.search.region = action.payload;
      state.search.station = null;
      state.search.isIncludeSIN = false;
    },
    setStation: (state, action) => {
      state.search.station = action.payload;
    },
    setIncludeSIN: (state, action) => {
      state.search.isIncludeSIN = action.payload;
    },
    setPartNo: (state, action) => {
      state.search.partNo = action.payload.partNo;
      state.search.wildcards = action.payload.wildcards;
    },
    removePartNo: (state, action) => {
      const partNoType = action.payload.includes("*") ? "wildcards" : "partNo";
      const index = state.search[partNoType].indexOf(action.payload);
      if (index !== -1) {
        state.search[partNoType].splice(index, 1);
      }
    },
    setDescription: (state, action) => {
      state.search.description = action.payload;
    },
    setError: (state, action) => {
      state.search.error = action.payload;
    },
    resetState: (state) => {
      state.search = initialState.search;
      state.api = initialState.api;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegionStation.pending, (state, action) => {
        if (state.api.regionStation.loading === "idle") {
          state.api.regionStation.loading = "pending";
          state.api.regionStation.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchRegionStation.fulfilled, (state, action) => {
        if (
          state.api.regionStation.loading === "pending" &&
          state.api.regionStation.currentRequestId === action.meta.requestId
        ) {
          state.search.region = { label: "All", value: "All" };
          state.api.regionStation.loading = "idle";
          state.api.regionStation.data = action.payload;
          state.api.regionStation.currentRequestId = undefined;
        }
      })
      .addCase(fetchRegionStation.rejected, (state, action) => {
        if (
          state.api.regionStation.loading === "pending" &&
          state.api.regionStation.currentRequestId === action.meta.requestId
        ) {
          state.api.regionStation.loading = "idle";
          state.api.regionStation.error = action.error;
          state.api.regionStation.currentRequestId = undefined;
        }
      })
      .addCase(fetchResultView.pending, (state, action) => {
        if (state.api.resultView.loading === "idle") {
          state.api.resultView.loading = "pending";
          state.api.resultView.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchResultView.fulfilled, (state, action) => {
        state.api.resultView.loading = "idle";
        state.api.resultView.currentRequestId = undefined;
        if (Array.isArray(action.payload)) {
          state.api.resultView.data = action.payload;
          state.api.resultView.fullViewData = action.payload.reduce(
            (previous, current) => {
              if (Array.isArray(current?.parts)) {
                return [...previous, ...current.parts];
              }
              return previous;
            },
            []
          );
          if (action.payload.length === 0) {
            state.search.error = errors.reponseNotAvailable;
          }
        }
      })
      .addCase(fetchResultView.rejected, (state, action) => {
        if (
          state.api.resultView.loading === "pending" &&
          state.api.resultView.currentRequestId === action.meta.requestId
        ) {
          state.api.resultView.loading = "idle";
          state.api.resultView.error = action.error;
          state.api.resultView.currentRequestId = undefined;
        }
      });
  },
});

const selfSelect = (state) => state.viewStocks;

export const selectFullViewData = createSelector(
  selfSelect,
  (state) => state.api.resultView.fullViewData
);

export const selectFeetGroup = createSelector(
  selfSelect,
  (state) => state.search.feetGroup
);

export const selectIncludeSIN = createSelector(
  selfSelect,
  (state) => state.search.isIncludeSIN
);

export const selectPartNo = createSelector(
  selfSelect,
  (state) => state.search.partNo
);

export const selectRegion = createSelector(
  selfSelect,
  (state) => state.search.region
);

export const selectStation = createSelector(
  selfSelect,
  (state) => state.search.station
);

export const selectDescription = createSelector(
  selfSelect,
  (state) => state.search.description
);

export const selectWildcards = createSelector(
  selfSelect,
  (state) => state.search.wildcards
);

export const selectError = createSelector(
  selfSelect,
  (state) => state.search.error
);

export const selectRegionStation = createSelector(selfSelect, (state) => {
  return state.api.regionStation.data;
});

export const selectResultView = createSelector(
  selfSelect,
  (state) => state.api.resultView.data
);

export const selectResultViewLoading = createSelector(
  selfSelect,
  (state) => state.api.resultView.loading === "pending"
);

export const selectRegionStationLoading = createSelector(
  selfSelect,
  (state) => state.api.regionStation.loading
);

export const {
  setFeetGroup,
  setIncludeSIN,
  setPartNo,
  setRegion,
  setStation,
  setDescription,
  setError,
  removePartNo,
  resetState,
} = viewStocksSlice.actions;

export default viewStocksSlice.reducer;
