import { apiSlice } from "../../app/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTableByType: builder.query({
      query: ({ pageIndex, pageSize, globalFilter, sortBy,status }) => ({
        url: `/spare/confirm-receipt-sin/list`,
        method: "POST",
        data: {
          page: pageIndex + 1,
          perPage: pageSize,
          status : status,
          search: globalFilter,
          ...(sortBy[0] && {
            sortField: sortBy[0]?.id,
            sortType: sortBy[0]?.desc ? "DESC" : "ASC",
          }),
        },
        headers: {
          "content-type": "application/json",
        },
      }),
      transformResponse: (response) => {

        return {
          elements: response?.elements || [],
          pageCount: response?.pageCount || 0,
        };
      },
    }),
    getDSDetails: builder.query({
      query: ({ ids }) => ({
        url: `/spare/confirm-receipt-sin/get-details`,
        method: "POST",
        data: {
          ids : ids
        },
        headers: {
          "content-type": "application/json",
        },
      }),
      transformResponse: (response) => {
        return response;
      },
    }),

    postCRInfo: builder.mutation({
      query: (data) => {
        return {
          url: `spare/confirm-receipt/saving-multiple-image`,
          method: "POST",
          data,
        };
      },
    }),
    getImageUrl: builder.query({
      query: (params) => {
        return {
          url: `spare/common/downloadImage`,
          method: "GET",
          params,
        };
      },
    }),
    getPdfUrl: builder.query({
      query: (params) => {
        return {
          url: `spare/common/downloadPdf`,
          method: "GET",
          params,
        };
      },
    }),
  }),
});

export const {
  useGetDSDetailsQuery,
  useLazyGetTableByTypeQuery,
  useLazyGetMMDCRTableByTypeQuery,
  usePostCRInfoMutation,
  useLazyGetImageUrlQuery,
  useLazyGetPdfUrlQuery,
} = extendedApiSlice;
