import { createSlice } from "@reduxjs/toolkit";

export const LogoutSlice = createSlice({
  name: "Logout",
  initialState: {
    showLogoutModal : false
  },
  reducers: {
    toggleShowLogoutModal: (state) => {
      state.showLogoutModal = !state.showLogoutModal;
    },
  },

});

// Action creators are generated for each case reducer function
export const { toggleShowLogoutModal } = LogoutSlice.actions;

export default LogoutSlice.reducer;
