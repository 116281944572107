import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import httpClient from "../../utils/httpClient";
import { ApiRouteEnums } from "../../constants/route";
import { TABLE_TYPES } from "../confirmReceipt/constant";

import { defaultPagination } from "./LoginConst";

export const LoginSlice = createSlice({
  name: "Login",
  initialState: {
    toggleUnlock: false,
    toggleChange: false,
    toggleForgot: false,
    privileges: [],
    fetchNotificationStatuses: {},
  },
  reducers: {
    toggleChange: (state) => {
      state.toggleChange = !state.toggleChange;
    },
    toggleUnlock: (state) => {
      state.toggleUnlock = !state.toggleUnlock;
    },
    toggleForgot: (state) => {
      state.toggleForgot = !state.toggleForgot;
    },
    setPrivileges: (state, action) => {
      state.privileges = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationCountsAsync.pending, (state, action) => {
        const { role } = action.meta.arg;
        state.fetchNotificationStatuses = {
          ...state.fetchNotificationStatuses,
          [role]: "loading",
        };
      })
      .addCase(fetchNotificationCountsAsync.fulfilled, (state, action) => {
        const { role, result } = action.payload;

        state.fetchNotificationStatuses = {
          ...state.fetchNotificationStatuses,
          [role]: "idle",
        };
        state.privileges = state.privileges.map((item) => {
          if (item.functionality === role) {
            return {
              ...item,
              notificationCounts: result,
            };
          }
          return item;
        });
      })
      .addCase(fetchNotificationCountsAsync.rejected, (state, action) => {
        const { role } = action.meta.arg;
        state.fetchNotificationStatuses = {
          ...state.fetchNotificationStatuses,
          [role]: "failed",
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const { toggleUnlock, toggleForgot, toggleChange, setPrivileges } =
  LoginSlice.actions;

// Async actions
export const fetchNotificationCountsAsync = createAsyncThunk(
  "login/fetchNotificationCounts",
  async ({ role }) => {
    if (role === "confirmReceipt") {
      const { data: { totalCount: CRTSpareListTotal = 0 } = {} } =
        await httpClient.get(ApiRouteEnums.FETCH_SPARE_CONFIRM_RECEIPT, {
          params: { tableType: TABLE_TYPES.SPARE, ...defaultPagination },
        });
      const { data: { totalCount: CRTRejectedTotal = 0 } = {} } =
        await httpClient.get(ApiRouteEnums.FETCH_SPARE_CONFIRM_RECEIPT, {
          params: { tableType: TABLE_TYPES.REJECTED, ...defaultPagination },
        });

      return {
        result: CRTSpareListTotal + CRTRejectedTotal,
        role,
      };
    }

    if (role === "confirmReceiptMmd") {
      const { data } = await httpClient.post(
        ApiRouteEnums.FETCH_SPARE_MMD_CONFIRM_RECEIPT,
        defaultPagination
      );

      return {
        role,
        result: data.data?.total || 0,
      };
    }

    if (role === "consumeSpareMmd") {
      const { data } = await httpClient.get(
        ApiRouteEnums.FETCH_CONSUME_SPARE_MMD,
        {
          params: defaultPagination,
        }
      );

      return {
        role,
        result: data.totalCount || 0,
      };
    }

    if (role === "confirmReceiptSin") {
      const { data } = await httpClient.post(
        ApiRouteEnums.FETCH_CONFIRM_RECEIPT_SIN,
        defaultPagination
      );

      return {
        role,
        result: data.total || 0,
      };
    }

    if (role === "dispatchSpare") {
      const { data } = await httpClient.post(
        ApiRouteEnums.FETCH_DISPATCH_SPARES,
        defaultPagination
      );

      return {
        role,
        result: data.total || 0,
      };
    }

    return {
      role,
    };
  }
);

export const sPrivileges = (rootState) => rootState.login.privileges;
export const sFetchNotificationStatuses = (rootState) =>
  rootState.login.fetchNotificationStatuses;

export default LoginSlice.reducer;
