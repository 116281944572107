import queryString from "query-string";
import { apiSlice } from "../../app/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCRTableByType: builder.query({
      query: ({ tableType, pageIndex = 0, pageSize = 10, globalFilter }) => ({
        url: `spare/confirm-receipt`,
        method: "GET",
        params: {
          tableType,
          page: pageIndex + 1,
          perPage: pageSize,
          value: globalFilter,
        },
      }),
      transformResponse: (response) => {
        return {
          elements: response?.data || [],
          pageCount: response?.pageCount || 0,
          total: response?.totalCount || 0,
        };
      },
    }),
    getMMDCRTableByType: builder.query({
      query: ({ pageIndex = 0, pageSize = 10, globalFilter, sortBy = [] }) => ({
        url: `spare/confirm-receipt/mmd/spares`,
        method: "POST",
        data: {
          page: pageIndex + 1,
          perPage: pageSize,
          search: globalFilter,
          ...(sortBy[0] && {
            sortField: sortBy[0]?.id,
            sortType: sortBy[0]?.desc ? "DESC" : "ASC",
          }),
        },
        headers: {
          "content-type": "application/json",
        },
      }),
      transformResponse: (response) => {
        return {
          elements: response?.data.elements || [],
          pageCount: response?.data.pageCount || 0,
          total: response?.data.total || 0,
        };
      },
    }),
    getCRDetails: builder.query({
      query: (params) => {
        const q = queryString.stringify(params, { arrayFormat: "comma" });
        return {
          url: `spare/confirm-receipt/details?${q}`,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
    postCRInfo: builder.mutation({
      query: (data) => {
        return {
          url: `spare/confirm-receipt/saving-multiple-image`,
          method: "POST",
          data,
        };
      },
    }),
    getImageUrl: builder.query({
      query: (params) => {
        return {
          url: `spare/common/downloadImage`,
          method: "GET",
          params,
        };
      },
    }),
    getPdfUrl: builder.query({
      query: (params) => {
        return {
          url: `spare/common/downloadPdf`,
          method: "GET",
          params,
        };
      },
    }),
  }),
});

export const {
  useGetCRDetailsQuery,
  useLazyGetCRTableByTypeQuery,
  useLazyGetMMDCRTableByTypeQuery,
  usePostCRInfoMutation,
  useLazyGetImageUrlQuery,
  useLazyGetPdfUrlQuery,
} = extendedApiSlice;
