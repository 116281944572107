import { apiSlice } from "../../app/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["MessageBroadcast"],
  endpoints: (builder) => ({
    getMessageBroadcast: builder.query({
      query: ({ page = 1, pageSize = 20, type, searchValue, filterValue }) => {
        return {
          url: `message/message-broadcast`,
          method: "GET",
          params: {
            type,
            page,
            perPage: pageSize,
            ...(searchValue && { searchValue }),
            filter: filterValue,
          },
        };
      },
    }),
    getRole: builder.query({
      query: () => {
        return {
          url: `message/message-broadcast/role`,
          method: "GET",
        };
      },
    }),
    postNewBroadcast: builder.mutation({
      query: (data) => {
        return {
          url: `message/message-broadcast/send-message`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: [{ type: "MessageBroadcast", id: "LIST" }],
    }),
  }),
});

export const {
  useLazyGetMessageBroadcastQuery,
  usePostNewBroadcastMutation,
  useGetRoleQuery,
} = extendedApiSlice;
