import ViewStock from "../../assets/icons/view-stock.svg";
import PositionSpare from "../../assets/icons/position-spare.svg";
import RegionalRelationship from "../../assets/icons/regional-relationship.svg";
import AllActionStatus from "../../assets/icons/all-actions-status.svg";
import ConfirmReceipt from "../../assets/icons/confirm-receript.svg";
import AccessManagement from "../../assets/icons/access-management.svg";
import ConsumeSpareIcon from "../../assets/icons/consume-spare.svg";
import STNReceiptVerification from "../../assets/icons/stn-receipt-verification.svg";
import dispatchSpareIcon from "../../assets/icons/dispatch-spare.svg";
import RecallSpareIcon from "../../assets/icons/recall-spare.svg";
import ConfirmReceiptSin from "../../assets/icons/confrim-receipt-sin.svg";
import { AppRouteEnums } from "../../constants/route";

export const headingText = "Overseas Line Station Portal";
export const downloadLink = "Download PDF";

export const defaultPagination = {
  page: 1,
  perPage: 10,
};

export const notificationCountWhitelist = [
  "confirmReceipt",
  "confirmReceiptMmd",
  "consumeSpareMmd",
  "confirmReceiptSin",
  "dispatchSpare",
];

export const pageRoles = [
  {
    functionality: "viewStock",
    route: AppRouteEnums.VIEW_STOCKS,
    icon: ViewStock,
    label: "View Stock",
  },
  {
    functionality: "regionsAndStations",
    route: AppRouteEnums.REGIONAL_RELATIONSHIP,
    icon: RegionalRelationship,
    label: "Regional Relationship",
  },
  {
    functionality: "allActionStatus",
    route: AppRouteEnums.ALL_ACTION_STATUS,
    icon: AllActionStatus,
    label: "All Actions Status",
  },
  {
    functionality: "positionSpare",
    route: AppRouteEnums.SPARE,
    icon: PositionSpare,
    label: "Position Spare",
  },
  {
    functionality: "confirmReceipt",
    route: AppRouteEnums.CONFIRM_RECEIPT,
    icon: ConfirmReceipt,
    label: "Confirm Receipt (STN)",
  },
  {
    functionality: "accessManagement",
    route: AppRouteEnums.ALL_ACCESS_MANAGEMENT,
    icon: AccessManagement,
    label: "Access Management",
  },
  {
    functionality: "confirmReceiptMmd",
    route: AppRouteEnums.MMD_OVERVIEW,
    icon: STNReceiptVerification,
    label: "STN Receipt Verification",
  },
  {
    functionality: "consumeSpare",
    route: AppRouteEnums.CONSUME_SPARE,
    icon: ConsumeSpareIcon,
    label: "Consume Spare",
  },
  {
    functionality: "dispatchSpare",
    route: AppRouteEnums.DISPATCH_SPARE,
    icon: dispatchSpareIcon,
    label: "Dispatch Spare",
  },
  {
    functionality: "consumeSpareMmd",
    route: AppRouteEnums.MMD_CONSUME_SPARE,
    icon: ConsumeSpareIcon,
    label: "Consume Spare - Verification",
  },
  {
    functionality: "recallSpareMmd",
    route: AppRouteEnums.MMD_RECALL_SPARE,
    icon: RecallSpareIcon,
    label: "Recall Spare",
  },
  {
    functionality: "confirmReceiptSin",
    route: AppRouteEnums.CONFIRM_RECEIPT_SIN,
    icon: ConfirmReceiptSin,
    label: "Confirm Receipt (SIN)",
  },
];
