import { configureStore } from "@reduxjs/toolkit";
import LoginSliceReducer from "../features/login/LoginSlice";
import LogoutSliceReducer from "../features/logout/LogoutSlice";
import UsersSliceReducer from "../features/user/UserSlice";
import ViewStocksReducer from "../features/viewstocks/ViewStocks.slice";
import SpareSliceReducer from "../features/spare/SpareSlice";
import RegionalRelationship from "../features/RegionalRelationship/RegionalRelationshipSlice";
import StationSliceReducer from "../features/stationList/StationSlice";
import ConfirmReceiptReducer from "../features/confirmReceipt/ConfirmReceipt.slice";
import DispatchSpareReducer from "../features/dispatchSpare/DispatchSpare.slice";
import AllActionStatusSliceReducer from "../features/allActionsStatus/AllActionStatus.slice";
import ConsumeSpareReducer from "../features/consumeSpare/ConsumeSpare.slice";
import MMDConsumeSpareReducer from "../features/mmdConsumeSpare/ConsumeSpare.slice";
import ConfirmReceiptSinReducer from "../features/confirmReceiptSin/ConfirmReceiptSin.slice";
import MessageBroadcastReducer from "../features/messageBroadcast/MessageBroadcast.slice";
import { apiSlice } from "./apiSlice";

export default configureStore({
  reducer: {
    login: LoginSliceReducer,
    logout: LogoutSliceReducer,
    users: UsersSliceReducer,
    viewStocks: ViewStocksReducer,
    spare: SpareSliceReducer,
    region: RegionalRelationship,
    station: StationSliceReducer,
    confirmReceipt: ConfirmReceiptReducer,
    dispatchSpare: DispatchSpareReducer,
    confirmReceiptSin: ConfirmReceiptSinReducer,
    allActionStatus: AllActionStatusSliceReducer,
    consumeSpare: ConsumeSpareReducer,
    mmdConsumeSpare: MMDConsumeSpareReducer,
    messageBroadcast: MessageBroadcastReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiSlice.middleware
    ),
});
