export const TitleProps = {
  $color: "#112690",
  $fontSize: 16,
  $fontWeight: 700,
  $lineHeight: 19,
  $mb: 3,
};

export const TABLE_TYPES = {
  SPARE: "06",
  REJECTED: "31",
  APPROVED: "21",
};

export const navigationFrom = {
  spare: "06",
  MMDSpare : "MMDSpareList",
  pending: "21",
  resubmit: "31",
};

export const mmdSpareData =
  {
    "success": true,
    "message": "",
    "data": {
      "page": 1,
      "perPage": 2,
      "total": 3,
      "pageCount": 2,
      "elements": [
      {
        "itdFormNo": "1000002855",
        "status": "21",
        "station": "ATL",
        "shipPriority": "Urgent",
        "shipMethod": "AIR",
        "approvedCertification": 1,
        "freightCharges": "RECHARGEABLE",
        "costCenter": "AAAKL401",
        "reasonDispatch": "SPARE REPLENIHSMENT",
        "finalAddress": "KLM Line Station LHR Terminal 4 Office 1051 (opposite stand 406) London Heathrow Airport TW6 3XZ United Kingdom",
        "approvalDate": "2023-06-19",
        "dnDate": "2023-06-19",
        "dnNote": "DN_note_ABC",
        "receiptDate": "2023-06-19",
        "flightNo": "Flight123",
        "awb": "awb_1234",
        "itdRefPdfLink": "1000002855.pdf",
        "dnPdfLink": "",
        "rejectionRemark": "",
        "createdBy": "yogalakshmi_m",
        "createdAt": "2023-06-18T17:00:00.000Z",
        "updatedAt": "2023-07-04T08:35:16.000Z",
        "partRefs": [
          {
            "id": 1,
            "partNo": "DEF456",
            "itdFormNo": "1000002855",
            "itdRefNo": "1000003437",
            "batchNo": "asfdsdfs",
            "serialNo": "1342fdwf",
            "quantity": 2,
            "arc": "ARC Value",
            "arcImageLink": "arcimg.link.com",
            "pickingSlip": "picking_slip1",
            "pickingSlipPdf": "pickingslip.pdf.link1",
            "dgrIndicator": "dgr_indicator1",
            "expiryDate": "2025-06-30",
            "uploadImageRefList": [
              {
                  "fileSize": 39760,
                  "fileName": "1000004324/testimage1.PNG"
              },
              {
                "fileSize": 39765,
                "fileName": "1000004325/testimage1.PNG"
            }
          ],
            "createdAt": "2023-07-04T08:34:32.000Z",
            "updatedAt": "2023-07-04T08:34:32.000Z"
          },
          {
            "id": 2,
            "partNo": "MNCS-C2-131000",
            "itdFormNo": "1000002855",
            "itdRefNo": "1000003436",
            "batchNo": "werwerwer",
            "serialNo": "sdfsf234",
            "quantity": 3,
            "arc": "ARC Value2",
            "arcImageLink": "arcimg2.link.com",
            "pickingSlip": "picking_slip2",
            "pickingSlipPdf": "pickingslip.pdf.link2",
            "dgrIndicator": "dgr_indicator2",
            "expiryDate": "2025-07-31",
            "uploadImageRefList": "",
            "createdAt": "2023-07-04T08:34:32.000Z",
            "updatedAt": "2023-07-04T08:34:32.000Z"
          },
          {
            "id": 3,
            "partNo": "MNCS-C2-130",
            "itdFormNo": "1000002855",
            "itdRefNo": "1000003438",
            "batchNo": "werwerwer",
            "serialNo": "sdfsf234",
            "quantity": 3,
            "arc": "ARC Value2",
            "arcImageLink": "arcimg2.link.com",
            "pickingSlip": "picking_slip2",
            "pickingSlipPdf": "pickingslip.pdf.link2",
            "dgrIndicator": "dgr_indicator2",
            "expiryDate": "2025-07-31",
            "uploadImageRefList": [
              {
                  "fileSize": 39760,
                  "fileName": "1000004324/testimage1.PNG"
              } 
            
          ],
            "createdAt": "2023-07-06T07:49:56.000Z",
            "updatedAt": "2023-07-06T07:49:56.000Z"
          }
        ]
      },
      {
        "itdFormNo": "1000002856",
        "status": "21",
        "station": "AKL",
        "shipPriority": "Urgent",
        "shipMethod": "AIR",
        "approvedCertification": 1,
        "freightCharges": "RECHARGEABLE",
        "costCenter": "AAAKL401",
        "reasonDispatch": "SPARE REPLENIHSMENT",
        "finalAddress": "KLM Line Station LHR Terminal 4 Office 1051 (opposite stand 406) London Heathrow Airport TW6 3XZ United Kingdom",
        "approvalDate": "2023-06-19",
        "dnDate": "2023-06-19",
        "dnNote": "DN_note_ABC",
        "receiptDate": "2023-06-19",
        "flightNo": "Flight123",
        "awb": "awb_1234",
        "itdRefPdfLink": "1000002855.pdf",
        "dnPdfLink": "",
        "rejectionRemark": "",
        "createdBy": "yogalakshmi_m",
        "createdAt": "2023-06-18T17:00:00.000Z",
        "updatedAt": "2023-07-06T07:55:53.000Z",
        "partRefs": [
          {
            "id": 5,
            "partNo": "DEF456-110",
            "itdFormNo": "1000002856",
            "itdRefNo": "1000003439",
            "batchNo": "asfdsdfs",
            "serialNo": "1342fdwf",
            "quantity": 2,
            "arc": "ARC Value",
            "arcImageLink": "arcimg.link.com",
            "pickingSlip": "picking_slip1",
            "pickingSlipPdf": "pickingslip.pdf.link1",
            "dgrIndicator": "dgr_indicator1",
            "expiryDate": "2025-06-30",
            "uploadImageRefList": [
              {
                  "fileSize": 39761,
                  "fileName": "1000004320/testimage1.PNG"
              },
              {
                "fileSize": 39764,
                "fileName": "1000004329/testimage1.PNG"
            }
          ],
            "createdAt": "2023-07-06T07:55:09.000Z",
            "updatedAt": "2023-07-06T07:55:09.000Z"
          }
        ]
      },
      {
        "itdFormNo": "1000002857",
        "status": "21",
        "station": "AKL",
        "shipPriority": "Urgent",
        "shipMethod": "AIR",
        "approvedCertification": 1,
        "freightCharges": "RECHARGEABLE",
        "costCenter": "AAAKL401",
        "reasonDispatch": "SPARE REPLENIHSMENT",
        "finalAddress": "KLM Line Station LHR Terminal 4 Office 1051 (opposite stand 406) London Heathrow Airport TW6 3XZ United Kingdom",
        "approvalDate": "2023-06-19",
        "dnDate": "2023-06-19",
        "dnNote": "DN_note_ABC",
        "receiptDate": "2023-06-19",
        "flightNo": "Flight123",
        "awb": "awb_1234",
        "itdRefPdfLink": "1000002855.pdf",
        "dnPdfLink": "",
        "rejectionRemark": "",
        "createdBy": "yogalakshmi_m",
        "createdAt": "2023-06-18T17:00:00.000Z",
        "updatedAt": "2023-07-06T07:55:53.000Z",
        "partRefs": [
          {
            "id": 6,
            "partNo": "DEF456-1100",
            "itdFormNo": "1000002857",
            "itdRefNo": "1000003440",
            "batchNo": "asfdsdfs",
            "serialNo": "1342fdwf",
            "quantity": 2,
            "arc": "ARC Value",
            "arcImageLink": "arcimg.link.com",
            "pickingSlip": "picking_slip1",
            "pickingSlipPdf": "pickingslip.pdf.link1",
            "dgrIndicator": "dgr_indicator1",
            "expiryDate": "2025-06-30",
            "uploadImageRefList": "",
            "createdAt": "2023-07-06T07:55:33.000Z",
            "updatedAt": "2023-07-06T07:55:33.000Z"
          }
        ]
      } ]
    }
  }

