import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import httpClient from "../../utils/httpClient";
import { extendedApiSlice } from "./apis";

export const submitSpareData = createAsyncThunk(
  "confirmReceiptSin/submitSpareData",
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        confirmReceiptSin: { details },
      } = getState();
      const iD = details.id;
      const requestNewStation = await httpClient.post(
        "/spare/confirm-receipt-sin/acknowledge",
        {
          id: iD,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      return requestNewStation.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const initialState = {
  overview: {
    DispatchSpareList: {
      data: [],
      selectedItems: [],
      loading: "idle",
      currentRequestId: undefined,
      error: null,
      page: 1,
      pageCount: 1,
      itdFormNo: "",
      onClick: false,
    },
    SubmitDispatchSpareList: {
      loading: "idle",
      currentRequestId: undefined,
      error: null,
      data: "",
      showModal: false,
      showError: false,
    },
  },
  details: {
    isBlocked: false,
    expireDates: {},
    imageKeys: {},
    data: [],
    scn: "",
    id: "",
    itdRefNo: "",
  },
};

const confirmReceiptSinSlice = createSlice({
  name: "confirmReceiptSin",
  initialState,
  reducers: {
    setDispatchSpareSelectedItems: (state, action) => {
      state.overview.DispatchSpareList.selectedItems = action.payload;
    },
    toggleShowModal: (state) => {
      state.overview.SubmitDispatchSpareList.showModal =
        !state.overview.SubmitDispatchSpareList.showModal;
    },
    
    toggleErrorModal: (state) => {
      state.overview.SubmitDispatchSpareList.showError =
        !state.overview.SubmitDispatchSpareList.showError;
    },
    setSCN: (state, action) => {
      state.details.scn = action.payload;
    },
    setID: (state, action) => {
      state.details.id = action.payload;
      console.log(state.details.id);
    },
    setImageKey: (state, action) => {
      const { type, key, value } = action.payload;
      if (type === "ADD") {
        state.details.itdRefNo =  key;
        if (state.details.imageKeys[key]) {
          state.details.imageKeys[key] = [
            ...state.details.imageKeys[key],
            value,
          ];
        } else {
          state.details.imageKeys[key] = [value];
        }
      }
      if (type === "REMOVE" && state.details.imageKeys[key]) {
        if (state.details.imageKeys[key].length === 1) {
          delete state.details.imageKeys[key];
        } else {
          state.details.imageKeys[key] = state.details.imageKeys[key].filter(
            (imageKey) => imageKey !== value
          );
        }
      }
    },
    setBlocked: (state, action) => {
      state.details.isBlocked = action.payload;
    },
    reset: (state) => {
      state = initialState;
    },
    resetDetails: (state) => {
      state.details = initialState.details;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitSpareData.pending, (state, action) => {
        if (state.overview.SubmitDispatchSpareList.loading === "idle") {
          state.overview.SubmitDispatchSpareList.loading = "pending";
          state.overview.SubmitDispatchSpareList.showModal = false;
          state.overview.SubmitDispatchSpareList.showError = false;
          state.overview.SubmitDispatchSpareList.error = "";
        }
      })
      .addCase(submitSpareData.fulfilled, (state, action) => {
        if (state.overview.SubmitDispatchSpareList.loading === "pending") {
          state.overview.SubmitDispatchSpareList.loading = "idle";
          console.log(action.payload)
          if(action.payload.success == true){
            state.overview.SubmitDispatchSpareList.showModal = true;
          console.log(state.overview.SubmitDispatchSpareList.showModal )}

            else{
              state.overview.SubmitDispatchSpareList.showError = true;
              state.overview.SubmitDispatchSpareList.error = action.payload?.message;
              console.log(state.overview.SubmitDispatchSpareList.showError)
            }
        }
      })
      .addCase(submitSpareData.rejected, (state, action) => {
        if (state.overview.SubmitDispatchSpareList.loading === "pending") {
          state.overview.SubmitDispatchSpareList.loading = "idle";
          state.overview.SubmitDispatchSpareList.error = action.error;
          state.overview.SubmitDispatchSpareList.error = action.payload?.message;
          state.overview.SubmitDispatchSpareList.showError = true;
        }
      })
      .addMatcher(
        extendedApiSlice.endpoints.getCRDetails.matchFulfilled,
        (state, { payload }) => {
          // console.log(payload);
          state.details.data = payload.reduce(
            (prev, { itdRefNo, itdFormNo }) => ({
              ...prev,
              [itdRefNo]: { itdRefNo, itdFormNo },
            }),
            {}
          );
        }
      );
  },
});

const selfSelect = (state) => state.confirmReceiptSin;

export const selectImageKeys = createSelector(
  selfSelect,
  (state) => state.details.imageKeys
);

export const selectDetailData = createSelector(
  selfSelect,
  (state) => state.details
);
export const showError = (state) =>
  state.confirmReceiptSin.overview.SubmitDispatchSpareList.showError;
  
export const selectBlocked = createSelector(
  selfSelect,
  (state) => state.details.isBlocked
);

export const DispatchSpareSelected = (state) =>
  state.confirmReceiptSin.overview.DispatchSpareList.selectedItems;
export const Scn = (state) => state.confirmReceiptSin.details.scn;
export const showModal = (state) =>
  state.confirmReceiptSin.overview.SubmitDispatchSpareList.showModal;
  export const imgKey = (state) =>  state.confirmReceiptSin.details.imageKeys;
  export const itdRefNo = (state) =>  state.confirmReceiptSin.details.itdRefNo;

  export const error = (state) =>
  state.dispatchSpare.overview.SubmitDispatchSpareList.error;
export const {
  setBlocked,
  setDispatchSpareSelectedItems,
  toggleShowModal,
  toggleErrorModal,
  setImageKey,
  setSCN,
  setID,
  reset,
  resetDetails,
} = confirmReceiptSinSlice.actions;

export default confirmReceiptSinSlice.reducer;
