export const errors = {
  noError: { type: "noError", message: "" },
  selectSpecific: {
    type: "selectSpecific",
    message: "Select specific region or station for wildcard searches.",
  },
  wildcardCombined: {
    type: "wildcardCombined",
    message:
      "Wildcard search may not be combined with actual part number searches.",
  },
  maxPartNo: {
    type: "maxPartNo",
    message: "The maximum limit for part number entry is 25.",
  },
  wildcardMinLength: {
    type: "wildcardMinLength",
    message: "Wildcard search must include a minimum of 4 characters.",
  },
  wildcardUnsupport: {
    type: "wildcardUnsupport",
    message: "Wildcard search is not possible when including SIN in search.",
  },
  invalidPartNo: {
    type: "invalidPartNo",
    message: "Has invalid part number value!",
  },
  reponseNotAvailable: {
    type: "reponseNotAvailable",
    message:
      "Sorry the requested part is not available, please change the search criteria",
  },
  selectBoth: {
    type: "selectBoth",
    message:
      "Please try removing part number / part description in the search criteria as selecting both are not allowed",
  },
};

export const warnings = {
  maxPartNo:
    "Maximum part number limit have been reached. Remove part number to add a new entry.",
  selectWildcard:
    "For wildcard searches, please select Region / Station after confirmation.",
};

export const feetGroupOptions = [
  { label: "A350", value: "A350" },
  { label: "A380", value: "A380" },
  { label: "B737", value: "B737" },
  { label: "B747", value: "B747" },
  { label: "B77W", value: "B77W" },
  { label: "B787", value: "B787" },
  { label: "Others", value: "Others" },
];

export const wildcardPattern = /^\*?[a-zA-Z0-9-:,. _]+\*?$/;
export const partNoPattern = /^[a-zA-Z0-9-:,. _]+$/;
export const descPattern = /^\*|.*\*$/;

export const tableDefaultSort = [{ id: "station", desc: false }];

export const regionResponse = {
  success: true,
  data: [
    {
      regionName: "Africa",
      stations: [
        {
          stationName: "Newark",
          stationCode: "EWR",
          countryCode: "USA",
          thaCode: "EWR",
        },
        {
          stationName: "Honolulu",
          stationCode: "HNL",
          countryCode: "USA",
          thaCode: "HNL",
        },
      ],
    },
  ],
};

export const tableResponse = {
  success: true,
  data: [
    {
      regionName: "America",
      parts: [
        {
          partNo: "351D9903-763",
          fleetGroup: "A350",
          description: "A350OEMS PBH: LATCH AY - TR L8",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0005850880",
          serialNo: "00437",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "IAH",
        },
        {
          partNo: "110CA06Y03",
          fleetGroup: "A350",
          description: "A350OEMS PBH:CONTACTOR - 3 PST NO 60A",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006527351",
          serialNo: "110CA01605",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "JFK2",
        },
        {
          partNo: "111CA06Y01",
          fleetGroup: "A350",
          description: "A350OEMS PBH: CONTACTOR - 3 PST NO 160A",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006527331",
          serialNo: "111CA02124",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "JFK2",
        },
        {
          partNo: "112CA06Y02",
          fleetGroup: "A350",
          description: "A350OEMS PBH:CONTACTOR",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006527342",
          serialNo: "112CA01360",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "JFK2",
        },
        {
          partNo: "107CA06Y00",
          fleetGroup: "",
          description: "A350OEMS PL : CONTACTOR - 3 PST NO 150A",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006537038",
          serialNo: "00732",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "JFK2",
        },
        {
          partNo: "38201-357",
          fleetGroup: "A350",
          description: "A350OEMS PBH: TUCD - THERMOSTAT UNIT",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0006123863",
          serialNo: "05591",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "LAX",
        },
        {
          partNo: "351D9903-763",
          fleetGroup: "A350",
          description: "A350OEMS PBH: LATCH AY - TR L8",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0005850876",
          serialNo: "00400",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "SFO",
        },
      ],
    },
    {
      regionName: "Europe",
      parts: [
        {
          partNo: "000000004000011040",
          fleetGroup: "B787-9 Consumable",
          description: "SEAL:'C'TYPE,INT PRESSURE  CI0013-02-12",
          class: "D",
          quantity: "1.000",
          uom: "EA",
          batchNo: "0000021280",
          serialNo: "",
          expiryDate: "0000-00-00",
          receivedDate: "2017-08-07",
          participants: "",
          station: "BCN",
        },
        {
          partNo: "000000004000038500",
          fleetGroup: "B787-9 Consumable",
          description: "NLET AY NOSE COWL PW4056/LEASEABLE ITEMB",
          class: "B",
          quantity: "10.000",
          uom: "EA",
          batchNo: "0000085475",
          serialNo: "",
          expiryDate: "0000-00-00",
          receivedDate: "2023-05-12",
          participants: "",
          station: "BCN",
        },
        {
          partNo: "351D9903-763",
          fleetGroup: "A350",
          description: "A350OEMS PBH: LATCH AY - TR L8",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0005863997",
          serialNo: "00446",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "FRA",
        },
        {
          partNo: "1940420026",
          fleetGroup: "A380",
          description: "A380OEMS CLP: SWITCH - REED, TRIPLE",
          class: "C",
          quantity: 1,
          uom: "",
          batchNo: "0005987958",
          serialNo: "",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "FRA",
        },
        {
          partNo: "110CA06Y03",
          fleetGroup: "A350",
          description: "A350OEMS PBH:CONTACTOR - 3 PST NO 60A",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006527349",
          serialNo: "110CA01598",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "FRA",
        },
        {
          partNo: "111CA06Y01",
          fleetGroup: "A350",
          description: "A350OEMS PBH: CONTACTOR - 3 PST NO 160A",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006527333",
          serialNo: "111CA02350",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "FRA",
        },
        {
          partNo: "112CA06Y02",
          fleetGroup: "A350",
          description: "A350OEMS PBH:CONTACTOR",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006527313",
          serialNo: "112CA00723",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "FRA",
        },
        {
          partNo: "107CA06Y00",
          fleetGroup: "",
          description: "A350OEMS PL : CONTACTOR - 3 PST NO 150A",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006537037",
          serialNo: "00733",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "FRA",
        },
        {
          partNo: "351D9903-763",
          fleetGroup: "A350",
          description: "A350OEMS PBH: LATCH AY - TR L8",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0005850879",
          serialNo: "00414",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "FRA",
        },
        {
          partNo: "1940420026",
          fleetGroup: "A380",
          description: "A380OEMS CLP: SWITCH - REED, TRIPLE",
          class: "C",
          quantity: 1,
          uom: "",
          batchNo: "0005991991",
          serialNo: "",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "LHR",
        },
        {
          partNo: "2000A0914K01",
          fleetGroup: "A380",
          description: "A380OEMS CLP: PACKING - SPOILE",
          class: "C",
          quantity: 1,
          uom: "",
          batchNo: "0002566311",
          serialNo: "",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "LHR",
        },
        {
          partNo: "9048A0030-01",
          fleetGroup: "A380",
          description: "A380OEMS CLP: GASKET",
          class: "C",
          quantity: 2,
          uom: "",
          batchNo: "0002566321",
          serialNo: "",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "LHR",
        },
        {
          partNo: "1618C0000-05",
          fleetGroup: "A380",
          description: "A380FHS: CONTROLLER - HEATER,ELECTRICAL",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0005652553",
          serialNo: "1618C00LI000206",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "LHR",
        },
        {
          partNo: "6863B040001",
          fleetGroup: "",
          description: "A380FHS: VALVE - HP BLEED",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0003514474",
          serialNo: "6863-00237",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "LHR",
        },
        {
          partNo: "6864A040001",
          fleetGroup: "",
          description: "A380FHS: BLEED VALVE,PRESSURE REGULATING",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0002209049",
          serialNo: "6864-00230",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "LHR",
        },
        {
          partNo: "351D9903-763",
          fleetGroup: "A350",
          description: "A350OEMS PBH: LATCH AY - TR L8",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0005863995",
          serialNo: "00441",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "MAN",
        },
        {
          partNo: "351D9903-763",
          fleetGroup: "A350",
          description: "A350OEMS PBH: LATCH AY - TR L8",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0005863998",
          serialNo: "00451",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "MUC",
        },
        {
          partNo: "351D9903-763",
          fleetGroup: "A350",
          description: "A350OEMS PBH: LATCH AY - TR L8",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0005863996",
          serialNo: "00443",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "MXP",
        },
      ],
    },
    {
      regionName: "North Asia",
      parts: [
        {
          partNo: "351D9903-763",
          fleetGroup: "A350",
          description: "A350OEMS PBH: LATCH AY - TR L8",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0005864003",
          serialNo: "00489",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "HND",
        },
      ],
    },
    {
      regionName: "South West Pacific",
      parts: [
        {
          partNo: "38201-357",
          fleetGroup: "A350",
          description: "A350OEMS PBH: TUCD - THERMOSTAT UNIT",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0005879417",
          serialNo: "00791",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "ADL",
        },
        {
          partNo: "38201-357",
          fleetGroup: "A350",
          description: "A350OEMS PBH: TUCD - THERMOSTAT UNIT",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0006135353",
          serialNo: "04779",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "CHC",
        },
        {
          partNo: "CA67001-026",
          fleetGroup: "A350",
          description: "A350MOOG PBH:SERVOCONTROL-EHA AILERON",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0006382417",
          serialNo: "00600781",
          expiryDate: null,
          receivedDate: null,
          vendorName: "MOOG",
          providerName: "",
          participants: "",
          station: "MEL",
        },
        {
          partNo: "CA67006-026",
          fleetGroup: "A350",
          description: "A350MOOG PBH:SERVOCONTROL-EHA ELEVATOR",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0005862213",
          serialNo: "01100701",
          expiryDate: null,
          receivedDate: null,
          vendorName: "MOOG",
          providerName: "",
          participants: "",
          station: "MEL",
        },
        {
          partNo: "351D9903-763",
          fleetGroup: "A350",
          description: "A350OEMS PBH: LATCH AY - TR L8",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0005850878",
          serialNo: "00408",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "MEL",
        },
        {
          partNo: "CA67002-026",
          fleetGroup: "A350",
          description: "A350MOOG PBH: ACTUATOR - SPOILER EBHA",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0006382412",
          serialNo: "00700943",
          expiryDate: null,
          receivedDate: null,
          vendorName: "MOOG",
          providerName: "",
          participants: "",
          station: "MEL",
        },
        {
          partNo: "CA67008-026",
          fleetGroup: "A350",
          description: "A350MOOG PBH: SERVOCONTROL - RUDDER EHA",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0006378622",
          serialNo: "01300623",
          expiryDate: null,
          receivedDate: null,
          vendorName: "MOOG",
          providerName: "",
          participants: "",
          station: "MEL",
        },
        {
          partNo: "110CA06Y03",
          fleetGroup: "A350",
          description: "A350OEMS PBH:CONTACTOR - 3 PST NO 60A",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006527359",
          serialNo: "110CA01616",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "MEL",
        },
        {
          partNo: "111CA06Y01",
          fleetGroup: "A350",
          description: "A350OEMS PBH: CONTACTOR - 3 PST NO 160A",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006527362",
          serialNo: "111CA02125",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "MEL",
        },
        {
          partNo: "112CA06Y02",
          fleetGroup: "A350",
          description: "A350OEMS PBH:CONTACTOR",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006527348",
          serialNo: "112CA01345",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "MEL",
        },
        {
          partNo: "107CA06Y00",
          fleetGroup: "",
          description: "A350OEMS PL : CONTACTOR - 3 PST NO 150A",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006537036",
          serialNo: "00734",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "MEL",
        },
        {
          partNo: "2000A0914K01",
          fleetGroup: "A380",
          description: "A380OEMS CLP: PACKING - SPOILE",
          class: "C",
          quantity: 1,
          uom: "",
          batchNo: "0002566311",
          serialNo: "",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "SYD",
        },
        {
          partNo: "9048A0030-01",
          fleetGroup: "A380",
          description: "A380OEMS CLP: GASKET",
          class: "C",
          quantity: 4,
          uom: "",
          batchNo: "0002566321",
          serialNo: "",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "SYD",
        },
      ],
    },
    {
      regionName: "West Asia  & Africa",
      parts: [
        {
          partNo: "351D9903-763",
          fleetGroup: "A350",
          description: "A350OEMS PBH: LATCH AY - TR L8",
          class: "A0",
          quantity: 1,
          uom: "",
          batchNo: "0005850877",
          serialNo: "00406",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "JNB",
        },
        {
          partNo: "110CA06Y03",
          fleetGroup: "A350",
          description: "A350OEMS PBH:CONTACTOR - 3 PST NO 60A",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006527354",
          serialNo: "110CA01759",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "JNB",
        },
        {
          partNo: "111CA06Y01",
          fleetGroup: "A350",
          description: "A350OEMS PBH: CONTACTOR - 3 PST NO 160A",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006527337",
          serialNo: "111CA02135",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "JNB",
        },
        {
          partNo: "112CA06Y02",
          fleetGroup: "A350",
          description: "A350OEMS PBH:CONTACTOR",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006527344",
          serialNo: "112CA01359",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "JNB",
        },
        {
          partNo: "107CA06Y00",
          fleetGroup: "",
          description: "A350OEMS PL : CONTACTOR - 3 PST NO 150A",
          class: "A",
          quantity: 1,
          uom: "",
          batchNo: "0006537039",
          serialNo: "00731",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "JNB",
        },
      ],
    },
    {
      regionName: "Airline house",
      parts: [
        {
          partNo: "1940420026",
          fleetGroup: "A380",
          description: "A380OEMS CLP: SWITCH - REED, TRIPLE",
          class: "C",
          quantity: 1,
          uom: "",
          batchNo: "0005987958",
          serialNo: "",
          expiryDate: null,
          receivedDate: null,
          vendorName: "OEMS",
          providerName: "",
          participants: "",
          station: "ALH",
        },
      ],
    },
  ],
};

export const fakeRegionApi = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(regionResponse);
    }, 350);
  });
};

export const fakeTableApi = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(tableResponse);
    }, 350);
  });
};

export const excelHeaderRow = [
  "Row No.",
  "Part No.",
  "Material Description",
  "Station",
  "Description",
  "Class",
  "Quantity",
  "UOM",
  "Batch No.",
  "Serial No.",
  "Expiry Date",
  "Receipt Date",
  "Vendor",
  "IATP",
  "Region",
];

export const tabsOptions = [
  { id: "fullview", label: "Full View" },
  { id: "regionbased", label: "Region-based" },
];
