import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../utils/axiosBaseQuery";

export const apiSlice = createApi({
  baseQuery: axiosBaseQuery({
    prepareHeaders: (headers) => {
      if (sessionStorage?.token) {
        headers["Authorization"] = `Bearer ${sessionStorage?.token}`;
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});
