import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpClient from "../../utils/httpClient";

export const postNewStation = createAsyncThunk(
  "station/postNewRegion",
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        station: { newStation },
      } = getState();
      const data = newStation;
      const requestNewStation = await httpClient.post(
        "/regionalRelationship/stations",
        data,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      return requestNewStation.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteStation = createAsyncThunk(
  "region/deleteStation",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { station } = getState();
      const requestDeleteStation = await httpClient.delete(
        `/regionalRelationship/stations/${station.selectedStation.id}`
      );
      return requestDeleteStation.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postEditStation = createAsyncThunk(
  "region/postEditStation",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { station } = getState();
      const data = station.newStation;
      const requestEditStation = await httpClient.patch(
        `/regionalRelationship/stations/${station.selectedStation.id}`,
        data,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      return requestEditStation.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const StationSliceReducer = createSlice({
  name: "station",
  initialState: {
    showAddStationModal: false,
    showDeleteStationModal: false,
    showEditStationModal: false,
    showStatusModal: false,
    newStation: {},
    selectedStation: {},
    reloadapi: false,
    api: {
      addStation: {
        data: "",
        loading: "idle",
        error: null,
      },
      editStation: {
        data: "",
        loading: "idle",
        error: null,
      },
      deleteStations: {
        data: "",
        loading: "idle",
        error: null,
      },
    },
  },
  reducers: {
    toggleAddStationModal: (state) => {
      state.showAddStationModal = !state.showAddStationModal;
    },
    toggleDeleteStationModal: (state) => {
      state.showDeleteStationModal = !state.showDeleteStationModal;
    },
    toggleEditStationModal: (state) => {
      state.showEditStationModal = !state.showEditStationModal;
    },
    toggleShowStatusModal: (state) => {
      state.showStatusModal = !state.showStatusModal;
    },
    setNewStation: (state, action) => {
      state.newStation = action.payload;
    },
    setSelectedStation: (state, action) => {
      state.selectedStation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postNewStation.pending, (state, action) => {
        if (state.api.addStation.loading === "idle") {
          state.api.addStation.loading = "pending";
          state.reloadapi = false;
        }
      })
      .addCase(postNewStation.fulfilled, (state, action) => {
        if (state.api.addStation.loading === "pending") {
          state.api.addStation.loading = "idle";
          state.api.addStation.data = action.payload;
          state.reloadapi = true;
        }
      })
      .addCase(postNewStation.rejected, (state, action) => {
        if (state.api.addStation.loading === "pending") {
          state.api.addStation.loading = "idle";
          state.api.addStation.error = action.error;
        }
      })
      .addCase(postEditStation.pending, (state, action) => {
        if (state.api.editStation.loading === "idle") {
          state.api.editStation.loading = "pending";
          state.reloadapi = false;
        }
      })
      .addCase(postEditStation.fulfilled, (state, action) => {
        if (state.api.editStation.loading === "pending") {
          state.api.editStation.loading = "idle";
          state.showStatusModal = true;
          state.reloadapi = true;
          state.api.editStation.data = action.payload;
        }
      })
      .addCase(postEditStation.rejected, (state, action) => {
        if (state.api.editStation.loading === "pending") {
          state.api.editStation.loading = "idle";
          state.api.editStation.error = action.error;
          state.showStatusModal = true;
          state.reloadapi = true;
        }
      })

      .addCase(deleteStation.pending, (state, action) => {
        if (state.api.deleteStations.loading === "idle") {
          state.api.deleteStations.loading = "pending";
          state.reloadapi = false;
        }
      })
      .addCase(deleteStation.fulfilled, (state, action) => {
        if (state.api.deleteStations.loading === "pending") {
          state.api.deleteStations.loading = "idle";
          state.api.deleteStations.data = action.payload;
          state.reloadapi = true;
        }
      })
      .addCase(deleteStation.rejected, (state, action) => {
        if (state.api.deleteStations.loading === "pending") {
          state.api.deleteStations.loading = "idle";
          state.api.deleteStations.error = action.error;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleAddStationModal,
  toggleDeleteStationModal,
  toggleEditStationModal,
  setNewStation,
  setSelectedStation,
  toggleShowStatusModal,
} = StationSliceReducer.actions;

export default StationSliceReducer.reducer;
