export const AppRouteEnums = {
  MAIN: "/",
  OLSP: "/olsp",
  OLSP_HELP: "/olsp/help",
  HELP: "/help",
  HOME: "/home",
  USER: "/user",
  SPARE: "/spare",
  ALL_ACCESS_MANAGEMENT: "/allAccessManagement",
  VIEW_STOCKS: "/viewstocks",
  CONFIRM_RECEIPT: "/confirm-receipt",
  CONFIRM_RECEIPT_DETAILS: "/confirm-receipt-details",
  ALL_ACTION_STATUS: "/all-action-status",
  REGIONAL_RELATIONSHIP: "/regionalrelationship",
  STATION_LIST: "/stationList",
  MMD_OVERVIEW: "/mmd-overview",
  MMD_DETAILS: "/mmd-details",
  CONSUME_SPARE: "/consume-spare",
  CONSUME_SPARE_DETAIL: "/consume-spare/:id",
  CONSUME_SPARE_DETAILS: "/consume-spare/details",
  MMD_CONSUME_SPARE: "/mmd-consume-spare",
  MMD_CONSUME_SPARE_DETAILS: "/mmd-consume-spare-details",
  DISPATCH_SPARE: "/dispatch-spare-overview",
  DISPATCH_SPARE_DETAILS: "/dispatch-spare-details",
  MMD_RECALL_SPARE: "/mmd-recall-spare",
  MESSAGE_BROADCAST: "/message-broadcast",
  MESSAGE_BROADCAST_DETAIL: "/message-broadcast/:id",
  CONFIRM_RECEIPT_SIN: "/confirm-receipt-sin-overview",
  CONFIRM_RECEIPT_SIN_DETAILS: "/confirm-receipt-sin-details",
  401: "/401",
  403: "/403",
};

export const ApiRouteEnums = {
  FETCH_POSITION_SPARE_TXN: "/all-action-status/position-spare-txn",
  FETCH_POSITIONED_SPARES:
    "/all-action-status/position-spare-txn/positioned-spares",
  FETCH_CONSUME_SPARE: "/all-action-status/consume-spare",
  FETCH_CONFIRM_RECEIPT_STN: "/all-action-status/confirm-receipt-in-sin",
  FETCH_POSITION_SPARE_COUNT: "/all-action-status/position-spare-txn/counts",
  SPARE_DOWNLOAD_ZIP: "/spare/common/downloadZip",
  SPARE_UPLOAD_IMAGE: "/spare/common/uploadImage",
  FETCH_PRIVILEGES: "/accessManagement/functionalities/eligible",
  FETCH_AVAILALE_SPARES: "/spare/consume-spare/get-available-spares",
  FETCH_REJECTED_SPARES: "/spare/consume-spare/get-rejected-by-mmd-spares",
  FETCH_SPARES_DETAIL: "/spare/consume-spare/get-spares-details",
  POST_SUBMIT_CONSUME_SPARE: "/spare/consume-spare/submit",
  FETCH_MMD_RECALL_SPARE: "/spare/recall-spare/get-spares",
  POST_SUBMIT_MMD_RECALL_SPARE: "/spare/recall-spare/submit",
  FETCH_SPARE_CONFIRM_RECEIPT: "/spare/confirm-receipt",
  FETCH_SPARE_MMD_CONFIRM_RECEIPT: "/spare/confirm-receipt/mmd/spares",
  FETCH_CONSUME_SPARE_MMD: "/spare/consume-spare/mmd/spares",
  FETCH_CONFIRM_RECEIPT_SIN: "/spare/confirm-receipt-sin/list",
  FETCH_DISPATCH_SPARES: "/spare/dispatch-spare/get-spares",
  DELETE_SUBMIT_CANCEL_CONSUME_SPARE:
    "/spare/consume-spare/cancel-rejected/:id",
  FETCH_MESSAGE_BROADCAST_DETAIL: "/message/message-broadcast/get-message/:id",
  FETCH_MESSAGE_RESPONSES:
    "/message/message-broadcast/get-message-response/:id",
  POST_SUBMIT_ACKNOWLEDGE: "/message/message-broadcast/acknowledgement/:id",
  FETCH_MESSAGE_BROADCAST_HEADER: "/message/message-broadcast/get-dashboard",
};
