import { apiSlice } from "../../app/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMMDConsumeSpareTable: builder.query({
      query: ({ pageIndex = 0, pageSize = 10, globalFilter, sortBy = [] }) => {
        return {
          url: `spare/consume-spare/mmd/spares`,
          method: "GET",
          params: {
            page: pageIndex + 1,
            perPage: pageSize,
            value: globalFilter,
            ...(sortBy[0] && {
              sortField: sortBy[0]?.id,
              sortType: sortBy[0]?.desc ? "DESC" : "ASC",
            }),
          },
        };
      },
      transformResponse: (response) => {
        return {
          elements: response?.data || [],
          pageCount: response?.pageCount || 0,
          total: response.totalCount || 0,
        };
      },
    }),
    getMMDConsumeSpareDetails: builder.query({
      query: (params) => {
        return {
          url: `spare/consume-spare/mmd/details`,
          method: "GET",
          params,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
    submitConsumeSpare: builder.mutation({
      query: (data) => {
        return {
          url: `spare/consume-spare/mmd/saving-consumed-spare`,
          method: "POST",
          data,
        };
      },
    }),
  }),
});

export const {
  useLazyGetMMDConsumeSpareTableQuery,
  useGetMMDConsumeSpareDetailsQuery,
  useSubmitConsumeSpareMutation,
} = extendedApiSlice;
