import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import httpClient from "../../utils/httpClient";
import { extendedApiSlice } from "./apis";

export const approveMMDSpareData = createAsyncThunk(
  "confirmReceipt/approveMMDSpare",
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        confirmReceipt: { overview },
      } = getState();
      const data = overview.mmd.itdFormNo;
      const requestNewStation = await httpClient.patch(
        "/spare/confirm-receipt/mmd/approve",
        {
          itdFormNo: data,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      return requestNewStation.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const rejectMMDSpareData = createAsyncThunk(
  "confirmReceipt/rejectMMDSpare",
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        confirmReceipt: { overview },
      } = getState();

      const data = overview.mmd;
      const requestNewStation = await httpClient.patch(
        "/spare/confirm-receipt/mmd/reject",
        {
          itdFormNo: data.itdFormNo,
          rejectionRemark: data.rejectionRemark,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      return requestNewStation.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const initialState = {
  overview: {
    SpareList: {
      selectedItems: [],
    },
    RejectedReceipts: {
      selectItems: [],
    },
    SIAApproval: {
      selectedItems: [],
    },
    MMDSpareList: {
      data: [],
      selectedItems: [],
      loading: "idle",
      currentRequestId: undefined,
      error: null,
      page: 1,
      pageCount: 1,
    },
    ApproveMMDSpareList: {
      loading: "idle",
      currentRequestId: undefined,
      error: null,
    },
    RejectMMDSpareList: {
      loading: "idle",
      currentRequestId: undefined,
      error: null,
    },
    mmd: {
      rejectModal: false,
      confirmRejectModal: false,
      acceptModal: false,
      confirmAcceptModal: false,
      confirmAcceptErrorModal: false,
      itdFormNo: "",
      rejectionRemark: "",
      onClick: false,
      error :"",
    },
  },
  details: {
    isBlocked: false,
    expireDates: {},
    imageKeys: {},
    data: [],
    thaRemarks: {},
  },
};

const confirmReceiptSlice = createSlice({
  name: "confirmReceipt",
  initialState,
  reducers: {
    setSpareSelectedItems: (state, action) => {
      state.overview.SpareList.selectedItems = action.payload;
    },
    setMMDSpareSelectedItems: (state, action) => {
      state.overview.MMDSpareList.selectedItems = action.payload;
    },
    setSIAApprovelSelectedItems: (state, action) => {
      state.overview.SIAApproval.selectedItems = action.payload;
    },
    setMMDPage: (state, action) => {
      state.overview.MMDSpareList.page = action.payload;
    },

    setMMDItdFormNo: (state, action) => {
      state.overview.mmd.itdFormNo = action.payload;
    },
    setMMDOnClick: (state, action) => {
      state.overview.mmd.onClick = action.payload;
    },
    setMMDRejectionRemark: (state, action) => {
      state.overview.mmd.rejectionRemark = action.payload;
    },
    toggleRejectModal: (state) => {
      state.overview.mmd.rejectModal = !state.overview.mmd.rejectModal;
    },
    toggleConfirmRejectModal: (state) => {
      state.overview.mmd.confirmRejectModal =
        !state.overview.mmd.confirmRejectModal;
    },

    toggleAcceptModal: (state) => {
      state.overview.mmd.acceptModal = !state.overview.mmd.acceptModal;
    },

    toggleConfirmAcceptModal: (state) => {
      state.overview.mmd.confirmAcceptModal =
        !state.overview.mmd.confirmAcceptModal;
    },
    toggleConfirmAcceptErrorModal: (state) => {
      state.overview.mmd.confirmAcceptErrorModal =
        !state.overview.mmd.confirmAcceptErrorModal;
    },
    setExpireDate: (state, action) => {
      const { key, value } = action.payload;
      state.details.expireDates[key] = value;
    },
    setImageKey: (state, action) => {
      const { type, key, value } = action.payload;
      if (type === "ADD") {
        if (state.details.imageKeys[key]) {
          state.details.imageKeys[key] = [
            ...state.details.imageKeys[key],
            value,
          ];
        } else {
          state.details.imageKeys[key] = [value];
        }
      }
      if (type === "REMOVE" && state.details.imageKeys[key]) {
        if (state.details.imageKeys[key].length === 1) {
          delete state.details.imageKeys[key];
        } else {
          state.details.imageKeys[key] = state.details.imageKeys[key].filter(
            (imageKey) => imageKey !== value
          );
        }
      }
    },
    setTHARemark: (state, action) => {
      const { key, value } = action.payload;
      state.details.thaRemarks[key] = value;
    },
    setBlocked: (state, action) => {
      state.details.isBlocked = action.payload;
    },
    reset: () => initialState,
    resetDetails: (state) => {
      state.details = initialState.details;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(approveMMDSpareData.pending, (state, action) => {
        if (state.overview.ApproveMMDSpareList.loading === "idle") {
          state.overview.ApproveMMDSpareList.loading = "pending";
          state.overview.mmd.confirmAcceptModal = false;
          state.overview.mmd.confirmAcceptErrorModal = false;
          state.overview.mmd.error = ""
        }
      })
      .addCase(approveMMDSpareData.fulfilled, (state, action) => {
        if (state.overview.ApproveMMDSpareList.loading === "pending") {
          state.overview.ApproveMMDSpareList.loading = "idle";
          if(action.payload.success == true){
          state.overview.mmd.confirmAcceptModal = true;}
          else{
            state.overview.mmd.confirmAcceptErrorModal = true;
            state.overview.mmd.error = action.payload?.message;  
          }
        }
      })
      .addCase(approveMMDSpareData.rejected, (state, action) => {
        if (state.overview.ApproveMMDSpareList.loading === "pending") {
          state.overview.ApproveMMDSpareList.loading = "idle";
          state.overview.ApproveMMDSpareList.error = action.error;
          state.overview.mmd.confirmAcceptErrorModal = true; 
          state.overview.mmd.error = action.payload?.message; 
        }
      })
      .addCase(rejectMMDSpareData.pending, (state, action) => {
        if (state.overview.RejectMMDSpareList.loading === "idle") {
          state.overview.RejectMMDSpareList.loading = "pending";
          state.overview.mmd.confirmRejectModal = false;
        }
      })
      .addCase(rejectMMDSpareData.fulfilled, (state, action) => {
        if (state.overview.RejectMMDSpareList.loading === "pending") {
          state.overview.RejectMMDSpareList.loading = "idle";
          state.overview.mmd.confirmRejectModal = true;
        }
      })
      .addCase(rejectMMDSpareData.rejected, (state, action) => {
        if (state.overview.RejectMMDSpareList.loading === "pending") {
          state.overview.RejectMMDSpareList.loading = "idle";
          state.overview.RejectMMDSpareList.error = action.error;
        }
      })
      .addMatcher(
        extendedApiSlice.endpoints.getCRDetails.matchFulfilled,
        (state, { payload }) => {
          state.details.data = payload.reduce(
            (prev, { itdRefNo, itdFormNo }) => ({
              ...prev,
              [itdRefNo]: { itdRefNo, itdFormNo },
            }),
            {}
          );
        }
      );
  },
});

const selfSelect = (state) => state.confirmReceipt;

export const selectSpareSelected = createSelector(
  selfSelect,
  (state) => state.overview.SpareList.selectedItems
);

export const selectSIAApprovalSelected = createSelector(
  selfSelect,
  (state) => state.overview.SIAApproval.selectedItems
);

export const selectExpireDate = createSelector(
  selfSelect,
  (state) => state.details.expireDates
);

export const selectImageKeys = createSelector(
  selfSelect,
  (state) => state.details.imageKeys
);

export const selectDetailData = createSelector(
  selfSelect,
  (state) => state.details
);

export const selectBlocked = createSelector(
  selfSelect,
  (state) => state.details.isBlocked
);

export const selectTHARemarks = createSelector(
  selfSelect,
  (state) => state.details.thaRemarks
);

export const approveMMDPending = (state) =>
  state.confirmReceipt.overview.ApproveMMDSpareList.loading;
export const rejectMMDPending = (state) =>
  state.confirmReceipt.overview.RejectMMDSpareList.loading;

export const MMDSelectSpareData = (state) =>
  state.confirmReceipt.overview.MMDSpareList.data;
export const MMDSelectSpareLoading = (state) =>
  state.confirmReceipt.overview.MMDSpareList.loading === "pending";
export const MMDSelectSpareSelected = (state) =>
  state.confirmReceipt.overview.MMDSpareList.selectedItems;
export const MMDPageCount = (state) =>
  state.confirmReceipt.overview.MMDSpareList.pageCount;
export const MMDItdFormNo = (state) =>
  state.confirmReceipt.overview.mmd.itdFormNo;
export const MMDOnClick = (state) => state.confirmReceipt.overview.mmd.onClick;
export const selectApprovalData = (state) =>
  state.confirmReceipt.overview.SIAApproval.data;
export const selectApprovalLoading = (state) =>
  state.confirmReceipt.overview.SIAApproval.loading;

export const showAcceptModal = (state) =>
  state.confirmReceipt.overview.mmd.acceptModal;
export const showAcceptSuccessModal = (state) =>
  state.confirmReceipt.overview.mmd.confirmAcceptModal;
  export const showAcceptErrorModal = (state) =>
  state.confirmReceipt.overview.mmd.confirmAcceptErrorModal;
export const showRejectModal = (state) =>
  state.confirmReceipt.overview.mmd.rejectModal;
export const showRejectSuccessModal = (state) =>
  state.confirmReceipt.overview.mmd.confirmRejectModal;


  export const error = (state) =>
  state.confirmReceipt.overview.mmd.error;
export const selectItems = (state, key) => {
  if (!key) {
    return null;
  }
  return state.confirmReceipt.overview[key].selectedItems;
};

export const {
  setBlocked,
  setSpareSelectedItems,
  setMMDSpareSelectedItems,
  setSIAApprovelSelectedItems,
  setMMDItdFormNo,
  setMMDRejectionRemark,
  setDetailsDirty,
  setMMDOnClick,
  setExpireDate,
  setImageKey,
  setTHARemark,
  setMMDPage,
  reset,
  resetDetails,
  toggleAcceptModal,
  toggleConfirmAcceptModal,
  toggleConfirmAcceptErrorModal,
  toggleRejectModal,
  toggleConfirmRejectModal,
} = confirmReceiptSlice.actions;

export default confirmReceiptSlice.reducer;
